//
// Typography
// --------------------------------------------------


// Heading line height

h1, .h1, .fs-1 { line-height: $h1-line-height; }
h2, .h2, .fs-2 { line-height: $h2-line-height; }
h3, .h3, .fs-3 { line-height: $h3-line-height; }
h4, .h4, .fs-4 { line-height: $h4-line-height; }
h5, .h5, .fs-5 { line-height: $h5-line-height; }
h6, .h6, .fs-6 { line-height: $h6-line-height; }


// Lists

ul, ol, dt {
  li {
    margin-bottom: $spacer * .25;
  }
}


// Description lists

dt {
  color: $headings-color;
}


// Blockquotes

.blockquote {
  position: relative;
  color: $blockquote-color;
  font-weight: $blockquote-font-weight;
  &::before {
    display: inline-block;
    font: {
      family: $icons-font-family;
      size: $blockquote-icon-size;
    }
    color: $blockquote-icon-color;
    content: $fi-quote;
  }
}
.blockquote-footer { margin-top: 0; }


// new styles 

.main-color {
  color: #fd5631 !important;
}

.icon-box-media {
  svg {
    width: 20px;
    height: auto;
  }
}

.text-primary {
  fill: #fd5631 !important;
}

.text-accent {
  fill: #5d3cf2 !important;
}

.text-secondary {
  fill: #f5f4f8 !important;
}

.text-success {
  fill: #07c98b !important;
}

.text-info {
  fill: #3c76f2 !important;
}

.text-warning {
  fill: #fdbc31 !important;
}

.text-danger {
  fill: #f23c49 !important;
}

.text-light {
  fill: #fff !important;
}

.text-dark {
  fill: #1f1b2d !important;
}

.text-white {
  fill: #fff !important;
}

.text-body {
  fill: #666276 !important;
}

.text-muted {
  fill: #9691a4 !important;
}

.text-nav {
  fill: #454056 !important;
}

.text-red {
  fill: #ff0000 !important;
}

.bg-faded-red {
  background-color: rgba(#ff0000, 0.1) !important;
}

.text-yellow {
  fill: #ffe900 !important;
}

.bg-faded-yellow {
  background-color: rgba(#ffe900, 0.1) !important;

}

.text-pink {
  fill: #aa00ff !important;
}

.bg-faded-pink {
  background-color: rgba(#aa00ff, 0.1) !important;

}

.icon-box:hover  {

  .icon-box-media.bg-faded-red {
    background-color: #ff0000 !important;
  }

  .icon-box-media.bg-faded-yellow {
    background-color: #ffe900!important;
  }

  .icon-box-media.bg-faded-pink {
    background-color: #aa00ff!important;
  }

} 

.icon-box .icon-box-media svg {
  transition: fill .22s ease-in-out;

  
} 

.icon-box:hover .icon-box-media svg {
  fill: #fff !important;


} 

.icon-box-media-wrapper  {
  justify-content: center;

/*   .icon-box {
    width: 20%;
  } */
  
}

.tns-carousel-container {

  .tns-carousel-wrapper.tns-nav-outside [data-controls] {
    margin-top: -17px !important;
  }
}

label.error {
  background-color: #ff0000;
  font-size: 80%;
  color: #fff;
  border-radius: 0.5rem;
  padding: 5px 8px;
  position: relative;
  display: inline-block;
  margin-top: 6px;
}

.add-btn {
  cursor: pointer;
  text-decoration: none !important;

}

.cloner-remove {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #d60202;
  font-size: 14px;
  text-decoration: none !important;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
  z-index: 2;

  span {
    font-weight: 700 !important;
  }

  svg {
    fill: #d60202;
    width: 17px;
    height: 17px;
    margin-right: 7px;
  }
}

.relative {
  position: relative;
}

.margin-product-alt {
  margin-bottom: -30px !important;
}

.hidden-new {
  display: none !important;
}

@media only screen and (max-width: 1000px) {

  .hidden-new,
  .visible-sm-new {
      display: block !important;
  }
}



.fileuploader-thumbnails-input-inner {
  border-radius: 8px !important;
  border: 1px solid #d5d2dc !important;
}


.fileuploader-thumbnails-input-inner svg path{
  fill: #fd5631 !important;
}

.fileuploader-thumbnails-input-inner:hover {
  border-color: #fd5631 !important;
  
  
}
.fileuploader-theme-thumbnails .fileuploader-item-inner,
.fileuploader-items .fileuploader-item .fileuploader-item-image {
  border-radius: 8px !important;
}

.fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
.fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item {
  width: calc(100%/4 - 10px) !important;
  height: 199px !important;
}

.fileuploader-theme-thumbnails .fileuploader-thumbnails-input-inner:active {
  background: #fd5631 !important;
}


@media only screen and (max-width: 1399px) {
  .fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
  .fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item {
      height: 150px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
  .fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item {
      height: 152px !important;
  }

}

@media only screen and (max-width: 800px) {
  .fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
  .fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item {
      width: calc(100%/3 - 10px) !important;
      height: 152px !important;
  }

}

@media only screen and (max-width: 500px) {
  .fileuploader-theme-thumbnails .fileuploader-thumbnails-input,
  .fileuploader-theme-thumbnails .fileuploader-items-list .fileuploader-item {
      width: calc(100%/2 - 10px) !important;
      height: 152px !important;
  }
}

.hidden {
  display: none;
}

.offcanvas-header-new {

  @media only screen and (min-width: 1000px) {
    display: block !important;
    padding: 0 !important;
    box-shadow: none !important;
    width: 100%;

  }

}

.disabled {
  display: none !important;
}

.alert.alert-success {
  background-color: rgba(#fd5631,0.1) !important;
  border-color: #fd5631 !important;
  color: #fd5631 !important;
}

.tox-notifications-container {
  max-width: 500px !important;
  width: 100% !important;
}

/* .tox .tox-menubar {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(#d5d2dc,0.8) !important;
  }

} */

.tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid rgba(#d5d2dc,0.8) !important;
}

.section-img {
  display: block;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 0.5;
  }

  img {
    overflow: hidden;
    border-radius: 12px;
    width: 100%;
  }

}

.form-check-label-alt {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    flex-direction: column !important;
    align-items: flex-start;

    span:first-child {
      margin-bottom: 10px !important;
    } 
  }
}

#filters-sidebar form {

  @media only screen and (max-width: 991px) {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
}

.logo-abs {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  pointer-events: none;

}

.logo-abs-img {
  max-width: 100px;
  max-height: 60px;
  object-fit: scale-down;
  position: relative;
  overflow: hidden;

}
.main-logo {

}
.main-logo img {
  max-width: 250px;
  max-height: 80px;
  object-fit: scale-down;
  position: relative;
  overflow: hidden;
}

.jq-stars {
  display: inline-block;
}

.jq-rating-label {
  font-size: 22px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-family: helvetica, arial, verdana;
}

.jq-star {
  width: 100px;
  height: 100px;
  display: inline-block;
  cursor: pointer;
}

.jq-star-svg {
  padding-left: 3px;
  width: 100%;
  height: 100% ;
}

.jq-star:hover .fs-star-svg path {
}

.jq-star-svg path {
  /* stroke: #000; */
  stroke-linejoin: round;
}

/* un-used */
.jq-shadow {
  -webkit-filter: drop-shadow( -2px -2px 2px #888 );
  filter: drop-shadow( -2px -2px 2px #888 );
}

.star-rating-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: -1px;
  margin-bottom: 10px;

  svg {
    margin: 1px;
    width: 20px;
    height: auto;
  }
}

.opinions {
  margin-bottom: 30px;


  &__place {
    display:  flex;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $border-color;

    .opinions__name {
        font-size: 19px;
        margin-bottom: 4px;

    }

    .opinions__avatar {
        width: 80px;
        height: 80px;
    }
    .opinions__stars {
      margin-bottom: 0;
    
  }

}

  &__list {
      
  }

  &__review {
    display:  flex;
    flex-wrap: nowrap;
    margin-bottom: 30px;

      &:last-child {
          margin-bottom: 0;
      }

      .opinions__name {
          //color: $link-color;
      }

      .opinions__stars {
          margin-right: 15px;
          margin-bottom: 10px;
        
      }
  }

  &__left {
      flex-shrink: 0;
      margin-right: 20px;
  }

  &__right {
      min-width: 0;
  }

  &__avatar {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      overflow: hidden;

      img {
          width: 100%;
          height: 100%;
          max-width: none;
          object-fit: cover;
      }
  }

  &__name {
      color: #fd5631;
      font-weight: 700;
      margin-bottom: 2px;

      a {
          color: inherit;
          font-weight: inherit;
      }
  }

  &__score {
    display:  flex;
    flex-wrap: nowrap;
    align-items: center;
      line-height: 1;
      color: #E7711B;
      font-size: 21px;
      font-weight: 700;
  }

  &__rating {
      line-height: 1;
      margin-right: 10px;
      display: block;
      position: relative;
      font-weight: 700;
      top: 3px;
  }

  &__stars {
    display:  flex;
    flex-wrap: nowrap;
      line-height: 1;
      margin-bottom: 10px;

      .jq-star {
          flex-shrink: 0;
          width: 22px !important;
          height: 22px !important;
          margin-right: 3px;

          &:last-child {
              margin-right: 0;
          }
      }

  }

  &__google-powered {
      margin-top: 8px;
  }

  &__time {
      display: block;
      opacity: 0.3;
      font-size: 13px;
      margin-bottom: 3px;
  }

  &__feedback {


  }

  &__text {
      margin: 1px 0 0;
      font-size: 15px;

  }

} 
